import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Nav from '../components/nav'
import SEO from '../components/seo'
import HeroForm from '../components/heroForm'
import Box from '../components/box'
import Footer from '../components/footer'
import SectionsWithIcons from '../components/sectionsWithIcons'
import HeartbeatSignupForm from '../components/heartbeatSignupForm'
import HeaderText from '../components/headerText'
import SubText from '../components/subText'
import BrowserExtensionToggle from '../components/browserExtensionToggle'
import checkUtmParams from '../utils/checkUtmParams'

import WhyAlignment from '../icons/whyAlignment'
import WhyLeadership from '../icons/whyLeadership'
import WhyAccountability from '../icons/whyAccountability'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const Covid19 = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "continent.png" }) {
        childImageSharp {
          fixed(width: 370, height: 700, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      macbookVideo: file(relativePath: { eq: "macbook-placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pulseMobile: file(relativePath: { eq: "pulse-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 742, maxHeight: 430, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pulseExtension: file(relativePath: { eq: "pulse-extension.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  return (
    <>
      <SEO
        title="COVID-19 - Communicate more effectively with your workforce in times of crisis"
        description="Company-wide communication is critical during a crisis situation. You can leverage Pulse to instantly connect and communicate with your entire remote team."
      />
      <Nav />
      <HeroContainer>
        <HeroForm
          headerText="The Communication Platform For Remote Teams."
          subText="They’ve been telling you “Remote Work is the new Normal”. But your people are more stressed, work feels scattered and things are slipping through the cracks more than ever before. "
        />
        <HeroImage>
          <Img fixed={data.heroImage.childImageSharp.fixed} fadeIn={false} />
        </HeroImage>
      </HeroContainer>
      <PulseWebVideo>
        <Box
          width="43.75rem"
          maxWidth="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <HeaderText as="h3">
            Today <br /> You can change it.
          </HeaderText>
          <SubText as="p">
            Pulse empowers you and your team to stay aligned through long-form
            updates across projects, processes, decisions, strategy and more.
            Pulse is the first and last word in remote alignment.
          </SubText>
        </Box>
        <Box width="62rem" maxWidth="100vw">
          <Img fluid={data.macbookVideo.childImageSharp.fluid} fadeIn={false} />
        </Box>
      </PulseWebVideo>
      <SectionsWithIcons
        sections={[
          {
            icon: <WhyAlignment />,
            header: 'Faster Team Alignment',
            description:
              'Inter and intra-team alignment at their best. Seamlessly streamline updates to groups, teams, units, or even entire company departments – no matter where they are.',
            link: 'See Features',
            to: '/features',
          },
          {
            icon: <WhyLeadership />,
            header: 'Brilliant Leadership Comms',
            description:
              'Embed. Enrich. Enhance. Pro tools for Pro leaders to maximize their ability to inspire, motivate and electrify their people – even when not everyone is in the same room.',
            link: 'See Features',
            to: '/features',
          },
          {
            icon: <WhyAccountability />,
            header: 'Faultless Accountability',
            description:
              'Pulse notifies you when people read, skim or interact with your message. Always stay informed and nudge people as soon as they’re out of the loop.',
            link: 'See Features',
            to: '/features',
          },
        ]}
      />
      <Box marginTop="10.625rem" marginBottom="9.375rem" textAlign="center">
        <Box
          width="46.375rem"
          maxWidth="95%"
          height="auto"
          margin="auto"
          marginTop="5rem"
        >
          <HeaderText as="h3">Anywhere. Anytime</HeaderText>
          <SubText as="p">
            Whether you're at home or at the office, Pulse is ready whenever and
            wherever you are. Pulse, comment, catch-up, or even put those
            notifications on snooze — you can do it all with Pulse apps for
            iPhone, or Android phone.
          </SubText>
          <Box
            width="46.375rem"
            maxWidth="95%"
            height="auto"
            margin="auto"
            marginTop="3rem"
          >
            <Img
              fluid={data.pulseMobile.childImageSharp.fluid}
              fadeIn={false}
            />
          </Box>
        </Box>
      </Box>

      <BrowserExtensionToggle />

      <HeartbeatSignupForm
        headerText="communicate effortlessly
with your remote workforce."
        subText="Pulse helps the best remote teams to get more done, stay aligned more effectively, and accomplish what was thought impossible."
      />
      <Footer />
    </>
  )
}

const HeroContainer = styled.div`
  display: flex;
  height: 43.125rem;
  overflow: hidden;
  margin: auto;
  width: 100%;

  @media ${props => props.theme.device.laptopL} {
    width: 75rem;
  }
`

const HeroImage = styled.div`
  display: none;

  @media ${props => props.theme.device.laptop} {
    display: block;
    width: 23.125rem;
  }
`

const PulseWebVideo = styled.div`
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;

  @media ${props => props.theme.device.tablet} {
    padding-left: ${props => props.theme.spacing(4)};
    padding-right: ${props => props.theme.spacing(4)};
  }

  @media ${props => props.theme.device.laptop} {
    padding-left: ${props => props.theme.spacing(8)};
    padding-right: ${props => props.theme.spacing(8)};
  }
`

export default Covid19
