import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="168"
      height="16"
      fill="none"
      viewBox="0 0 168 16"
    >
      <path
        fill="url(#paint0_linear)"
        d="M3.58 12.303c3.534 0 5.528-2.168 5.528-5.745 0-3.554-1.994-5.7-5.37-5.7H0v11.445h3.58zm-2.175-1.23V2.088h2.243c2.719 0 4.1 1.7 4.1 4.471 0 2.794-1.381 4.516-4.259 4.516H1.405z"
      ></path>
      <path
        fill="url(#paint1_linear)"
        d="M13.843 12.505c1.518 0 2.311-.805 2.583-1.364h.068v1.162h1.337V6.648c0-2.728-2.107-3.04-3.217-3.04-1.314 0-2.81.446-3.49 2.011l1.27.447c.294-.626.99-1.296 2.265-1.296 1.23 0 1.835.642 1.835 1.743v.045c0 .637-.657.581-2.243.783-1.614.206-3.376.558-3.376 2.526 0 1.676 1.314 2.637 2.968 2.637zm.204-1.185c-1.065 0-1.835-.47-1.835-1.386 0-1.006.929-1.32 1.971-1.453.567-.067 2.085-.224 2.311-.492v1.207c0 1.073-.86 2.124-2.447 2.124z"
      ></path>
      <path
        fill="url(#paint2_linear)"
        d="M20.27 12.303h1.338V6.871c0-1.162.929-2.012 2.197-2.012.357 0 .725.067.816.09V3.607a13.018 13.018 0 00-.702-.022c-1.043 0-1.949.581-2.266 1.43h-.09V3.72H20.27v8.584z"
      ></path>
      <path
        fill="url(#paint3_linear)"
        d="M26.206 12.303h1.336V9.09l.935-.855 3.257 4.068h1.7l-3.949-4.918 3.676-3.666h-1.654l-3.806 3.823h-.159V.858h-1.336v11.445z"
      ></path>
      <path
        fill="url(#paint4_linear)"
        d="M39.368 12.303h1.337V6.938c0-1.257.929-2.146 1.97-2.146 1.015 0 1.723.654 1.723 1.632v5.88h1.36v-5.59c0-1.106.701-1.922 1.925-1.922.952 0 1.767.497 1.767 1.766v5.745h1.337V6.558c0-2.017-1.099-2.95-2.65-2.95-1.247 0-2.159.564-2.606 1.452h-.091c-.43-.916-1.19-1.453-2.334-1.453-1.133 0-1.97.537-2.333 1.453h-.114V3.72h-1.291v8.583z"
      ></path>
      <path
        fill="url(#paint5_linear)"
        d="M56.766 12.482c2.356 0 3.942-1.766 3.942-4.426 0-2.683-1.586-4.449-3.942-4.449-2.357 0-3.943 1.766-3.943 4.449 0 2.66 1.586 4.426 3.943 4.426zm0-1.185c-1.79 0-2.606-1.52-2.606-3.241s.816-3.264 2.606-3.264 2.605 1.543 2.605 3.264c0 1.721-.816 3.241-2.605 3.241z"
      ></path>
      <path
        fill="url(#paint6_linear)"
        d="M66.034 12.482c1.676 0 2.243-1.028 2.537-1.498h.159v1.32h1.291V.857h-1.336v4.225h-.114c-.294-.447-.815-1.476-2.515-1.476-2.197 0-3.715 1.722-3.715 4.427 0 2.727 1.518 4.448 3.693 4.448zm.181-1.185c-1.677 0-2.538-1.453-2.538-3.286 0-1.81.839-3.219 2.538-3.219 1.631 0 2.492 1.297 2.492 3.22 0 1.944-.883 3.285-2.492 3.285z"
      ></path>
      <path
        fill="url(#paint7_linear)"
        d="M76.303 12.482c1.767 0 3.059-.872 3.466-2.168l-1.29-.358c-.34.894-1.128 1.341-2.176 1.341-1.569 0-2.65-1-2.713-2.839h6.316V7.9c0-3.196-1.926-4.292-3.739-4.292-2.356 0-3.92 1.833-3.92 4.471s1.541 4.404 4.056 4.404zM73.59 7.318c.09-1.336 1.048-2.526 2.577-2.526 1.45 0 2.379 1.073 2.379 2.526H73.59z"
      ></path>
      <path
        fill="url(#paint8_linear)"
        d="M83.683 9.084h1.36v-.067c.023-1.386.385-1.99 1.382-2.604.997-.598 1.586-1.459 1.586-2.694 0-1.744-1.291-3.018-3.285-3.018-1.835 0-3.291 1.118-3.376 3.018h1.427c.085-1.319 1.02-1.855 1.949-1.855 1.065 0 1.926.693 1.926 1.788 0 .889-.516 1.526-1.179 1.923-1.11.665-1.772 1.313-1.79 3.442v.067zm.725 3.309c.561 0 1.02-.453 1.02-1.006 0-.553-.459-1.006-1.02-1.006-.56 0-1.019.453-1.019 1.006 0 .553.459 1.006 1.02 1.006z"
      ></path>
      <path
        fill="url(#paint9_linear)"
        d="M99.131.858v8.181c0 1.426-.69 2.191-1.903 2.191-1.104 0-1.903-.637-1.903-1.61h-1.382c0 1.761 1.382 2.84 3.285 2.84 2.017 0 3.308-1.224 3.308-3.42V.857h-1.405z"
      ></path>
      <path
        fill="url(#paint10_linear)"
        d="M108.653 8.794c0 1.61-1.246 2.347-2.243 2.347-1.11 0-1.903-.805-1.903-2.057V3.72h-1.337v5.455c0 2.19 1.178 3.241 2.809 3.241 1.314 0 2.175-.693 2.583-1.565h.091v1.453h1.337V3.72h-1.337v5.075z"
      ></path>
      <path
        fill="url(#paint11_linear)"
        d="M118.601 5.642c-.419-1.219-1.36-2.035-3.082-2.035-1.835 0-3.194 1.029-3.194 2.482 0 1.184.713 1.978 2.311 2.347l1.45.335c.878.201 1.291.615 1.291 1.207 0 .738-.793 1.342-2.039 1.342-1.093 0-1.778-.464-2.016-1.386l-1.269.313c.311 1.458 1.529 2.235 3.308 2.235 2.022 0 3.398-1.09 3.398-2.57 0-1.197-.759-1.951-2.311-2.326l-1.291-.313c-1.031-.251-1.495-.592-1.495-1.251 0-.738.793-1.275 1.857-1.275 1.167 0 1.649.638 1.881 1.23l1.201-.335z"
      ></path>
      <path
        fill="url(#paint12_linear)"
        d="M124.555 3.72h-1.858V1.661h-1.336V3.72h-1.314v1.118h1.314v5.365c0 1.498 1.223 2.213 2.356 2.213.498 0 .816-.09.997-.156l-.272-1.185c-.113.022-.295.067-.589.067-.589 0-1.156-.179-1.156-1.297V4.837h1.858V3.719z"
      ></path>
      <path
        fill="url(#paint13_linear)"
        d="M134.836 3.72h-1.858V1.661h-1.337V3.72h-1.314v1.118h1.314v5.365c0 1.498 1.224 2.213 2.357 2.213.498 0 .815-.09.996-.156l-.271-1.185c-.114.022-.295.067-.59.067-.589 0-1.155-.179-1.155-1.297V4.837h1.858V3.719z"
      ></path>
      <path
        fill="url(#paint14_linear)"
        d="M136.847 12.303h1.336V6.871c0-1.162.929-2.012 2.198-2.012.357 0 .725.067.816.09V3.607a13.022 13.022 0 00-.703-.022c-1.042 0-1.948.581-2.265 1.43h-.091V3.72h-1.291v8.584z"
      ></path>
      <path
        fill="url(#paint15_linear)"
        d="M143.907 15.5c1.207 0 2.062-.632 2.56-1.945l3.705-9.814-1.462-.022-2.401 6.84h-.091l-2.401-6.84h-1.451l3.218 8.629-.249.67c-.476 1.286-1.088 1.409-2.062 1.163l-.34 1.163c.136.067.521.156.974.156z"
      ></path>
      <path
        fill="url(#paint16_linear)"
        d="M156.427 12.303h1.336V3.72h-1.336v8.584zm.679-10.015c.521 0 .952-.402.952-.894S157.627.5 157.106.5c-.521 0-.951.402-.951.894s.43.894.951.894z"
      ></path>
      <path
        fill="url(#paint17_linear)"
        d="M163.995 3.72h-1.858V1.661h-1.336V3.72h-1.314v1.118h1.314v5.365c0 1.498 1.223 2.213 2.356 2.213.499 0 .816-.09.997-.156l-.272-1.185c-.113.022-.294.067-.589.067-.589 0-1.156-.179-1.156-1.297V4.837h1.858V3.719z"
      ></path>
      <path
        fill="url(#paint18_linear)"
        d="M166.98 12.393c.561 0 1.02-.453 1.02-1.006 0-.553-.459-1.006-1.02-1.006-.56 0-1.019.453-1.019 1.006 0 .553.459 1.006 1.019 1.006z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="0"
          x2="168.477"
          y1="8"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Icon
