import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import Box from './box'
import HeaderText from './headerText'
import SubText from './subText'
import DarkModeToggle from '../icons/darkModeToggle'
import LighModeToggle from '../icons/lightModeToggle'

const BrowserExtensionToggle = () => {
  const data = useStaticQuery(graphql`
    query {
      pulseExtension: file(relativePath: { eq: "pulse-extension.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pulseExtensionDark: file(
        relativePath: { eq: "pulse-extension-dark.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [isDarkMode, setIsDarkMode] = useState(false)

  const handleChange = () => {
    setIsDarkMode(!isDarkMode)
  }

  return (
    <BrowserExtension isDarkMode={isDarkMode}>
      <Box className="browser-about">
        <HeaderText style={{ color: isDarkMode ? 'black' : '#FFFFFF' }} as="h3">
          meet our browser extension
        </HeaderText>
        <SubText style={{ color: isDarkMode ? '#545454' : '#FFFFFF' }} as="p">
          Install in just a few clicks on all your team members’ devices and
          display the latest and most important pulses simply and where no one
          can miss them: in everyone's new browser tab.
        </SubText>
        <Box display="flex" alignItems="center">
          <Box>
            <input
              type="checkbox"
              id="toggle"
              className="checkbox"
              onChange={handleChange}
            />
            <label htmlFor="toggle" className="switch"></label>
          </Box>
          <Box width="20px" />
          {isDarkMode ? <LighModeToggle /> : <DarkModeToggle />}
        </Box>
      </Box>
      <Box maxWidth="100%" width="56.25rem">
        {isDarkMode ? (
          <Img
            fluid={data.pulseExtensionDark.childImageSharp.fluid}
            fadeIn={false}
          />
        ) : (
          <Img
            fluid={data.pulseExtension.childImageSharp.fluid}
            fadeIn={false}
          />
        )}
      </Box>
    </BrowserExtension>
  )
}

const BrowserExtension = styled.div`
  transition: all 200ms;
  background-color: ${props => (props.isDarkMode ? '#F6F6F6' : 'black')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12.5rem;
  margin-left: 0rem;
  margin-right: 0rem;
  height: 45.5rem;

  .browser-about {
    width: 37.5rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.875rem;
    margin-top: 12.5rem;
  }

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 25.375rem;
    margin-left: 0rem;
    margin-right: 0rem;
    height: 43.75rem;

    .browser-about {
      margin-top: 28.125rem;
      max-width: 75rem;
    }
  }

  @media (min-width: 1440px) {
    max-width: 100vw;
    margin: 0;
    margin-bottom: 25.375rem;
    box-sizing: border-box;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #eee;
    border-radius: 20px;
    cursor: pointer;
  }

  .switch::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    top: 1px; // TO GIVE AN EFFECT OF CIRCLE INSIDE SWITCH.
    left: 1px;
    transition: all 0.3s;
  }

  .checkbox:checked + .switch::after {
    left: 20px;
  }
  .checkbox:checked + .switch {
    background-color: #545454;
  }

  .checkbox {
    display: none;
  }
`

export default BrowserExtensionToggle
